(function () {
  'use strict';

  /* @ngdoc object
   * @name learning.moduleSetEdit
   * @description
   *
   */
  angular
    .module('learning.moduleSetEdit', [
      'ui.router'
    ]);
}());
